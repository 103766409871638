<template>
  <div
    v-if="sectionData"
    class="
      py-12
      sm:py-24
      bg-gray-50
      flex flex-col
      justify-center
      pt-8
      sm:px-6
      lg:px-8
    "
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="text-center text-3xl font-extrabold text-gray-900">
        {{ sectionData[0].topText }}
      </h2>
      <p class="mt-2 text-center text-gray-600">
        Or
        {{ " " }}
        <router-link
          to="/register"
          class="font-medium text-blue-600 hover:text-blue-500"
        >
          {{ sectionData[0].middleText }}
        </router-link>
      </p>
    </div>
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <!-- Success message -->
        <div v-if="showSuccess" class="rounded-md bg-green-50 p-4 mb-8">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/check-circle -->
              <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">
                {{ message }}
              </h3>
              <!--<div class="mt-2 text-sm text-green-700">
                <p class="text-green-700  text-sm">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid pariatur, ipsum similique veniam.
                </p>
              </div>-->
            </div>
          </div>
        </div>
        <!-- Login failed message -->
        <div v-if="showFailure" class="rounded-md bg-red-50 p-4 mb-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/x-circle -->
              <svg
                class="h-5 w-5 text-red-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">Login failed</h3>
              <div class="mt-2 text-red-700">
                <p class=" text-red-700 text-sm ">{{ message ? message : "Please ensure your Username and Password have been entered correctly." }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- end Login failed message -->
        <LoadingSpinner v-if="isLoading" class="justify-center items-center" />
        <form v-if="!isLoading" class="space-y-6" @submit="login">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1 relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-5 w-5 text-gray-400"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg></div>
              <input
                :value="loginDetails.Email"
                @input="setLoginInfo('Email', $event.target.value)"
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  px-3
                  py-2
                    pl-10
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div class="mt-1">
              <input
                :value="loginDetails.Password"
                @input="setLoginInfo('Password', $event.target.value)"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required=""
                class="
                  appearance-none
                  block
                  w-full
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  placeholder-gray-400
                  focus:outline-none
                  focus:ring-indigo-500
                  focus:border-indigo-500
                  sm:text-sm
                "
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input
                :checked="rememberMe"
                v-model="rememberMe"
                id="remember-me"
                name="remember-me"
                type="checkbox"
                class="
                  h-4
                  w-4
                  text-indigo-600
                  focus:ring-indigo-500
                  border-gray-300
                  rounded
                "
              />
              <label for="remember-me" class="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            <div class="text-sm">
              <!-- <a href="#" class="font-medium text-blue-600 hover:text-blue-500">
                Forgot your password?
              </a> -->
              <router-link
                to="/ForgotPassword"
                class="font-medium text-blue-600 hover:text-blue-500"
              >
                Forgot your password?
              </router-link>
            </div>
          </div>

          <div>
            <button
              class="
                w-full
                flex
                justify-center
                whitespace-nowrap
                inline-flex
                items-center
                justify-center
                bg-origin-border
                px-4
                py-2
                border border-transparent
                rounded-md
                shadow-sm
                text-base
                font-medium
                text-white
                hover:from-blue-700 hover:to-blue-500
              "
              :disabled="!credsPresent"
              v-bind:class="[
                credsPresent
                  ? 'bg-gradient-to-r from-blue-500 to-blue-700 hover:bg-blue-700'
                  : 'bg-indigo-400 cursor-not-allowed',
              ]"
            >
              Sign In
            </button>
            <!-- <router-link to="/Dashboard" onclick="document.getElementById('signInButton').click();sessionStorage.setItem('login','true');" class="w-full flex justify-center whitespace-nowrap inline-flex items-center justify-center bg-gradient-to-r from-blue-500 to-blue-700 bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white hover:from-blue-700 hover:to-blue-500">
              Sign in
            </router-link > -->
          </div>
        </form>

        <div class="mt-6 hidden">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300" />
            </div>
            <div class="relative flex justify-center text-sm">
              <span class="px-2 bg-white text-gray-500">
                Or continue with
              </span>
            </div>
          </div>

          <div class="mt-6 grid grid-cols-3 gap-3">
            <div>
              <a
                href="#"
                class="
                  w-full
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  bg-white
                  text-sm
                  font-medium
                  text-gray-500
                  hover:bg-gray-50
                "
              >
                <span class="sr-only">Sign in with Facebook</span>
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                class="
                  w-full
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  bg-white
                  text-sm
                  font-medium
                  text-gray-500
                  hover:bg-gray-50
                "
              >
                <span class="sr-only">Sign in with Twitter</span>
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84"
                  />
                </svg>
              </a>
            </div>

            <div>
              <a
                href="#"
                class="
                  w-full
                  inline-flex
                  justify-center
                  py-2
                  px-4
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  bg-white
                  text-sm
                  font-medium
                  text-gray-500
                  hover:bg-gray-50
                "
              >
                <span class="sr-only">Sign in with GitHub</span>
                <svg
                  class="w-5 h-5"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "../components/LoadingSpinner.vue";
import { OcContentService } from "../services/ocContentService";
import { NopService } from "../services/nopService";
import { CookieService } from "../services/cookieService";
import { useReCaptcha } from 'vue-recaptcha-v3'

const loginDetails = {
  Password: "",
  Email: "",
};

export default {
  components: {
    LoadingSpinner,
  },
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded()
      // Execute reCAPTCHA with action "login".
      const gtoken = await executeRecaptcha('login')
      // Do stuff with the received token.
      let resp = await NopService.validateRecaptcha(gtoken)
      return (resp.data.status == 1)
    }
    return {
      recaptcha,
      loginDetails,
    };
  },
  data() {
    return {
      sectionData: null,
      passwordsMatch: false,
      rememberMe: false,
      isLoading: false,
      showSuccess: false,
      showFailure: false,
      failureMessage: "",
      credsPresent: false,
      guid: this.$route.query.guid,
      isValid: this.$route.query.isValid,
      isValidRecaptcha: false,
    };
  },
  created() {
    this.isLoading = true;
    if (this.guid && (this.isValid == 1)) {
      //we only need to validate a user if they are directed
      //to /login?guid=SOMETHING&?isValid=1
      this.validateGuid();
    }
    this.fetchOcData();
    this.getLoginDetails();
    this.isLoading = false;
  },
  methods: {
    async fetchOcData() {
      this.sectionData = await OcContentService.fetchPageData(
        "Login",
        "KrickersSectionContent"
      );
    },
    getLoginDetails() {
      this.loginDetails = {
        Password: "",
        Email: "",
      };
      let creds = CookieService.getRememberMeCookie();
      if (creds) {
        this.loginDetails = creds;
        this.rememberMe = true;
        this.credsPresent = true;
      }
    },
    async login(event) {
      event.preventDefault();
      this.message = "";
      this.isLoading = true;
      this.showSuccess = false;
      this.showFailure = false;
      this.isValidRecaptcha = await this.recaptcha();
      if (this.isValidRecaptcha && this.loginDetails.Email && this.loginDetails.Password) {
        this.isLoading = true;
        let resp = await NopService.LogIn(this.loginDetails, this.rememberMe);
        if (resp.data.Status == 1) {
          //login was successful
          this.emitter.emit("logged-in", true);
          this.$router.push({ path: "Dashboard" });
        }
        else {
          this.showFailure = true;
        }
      }
      else {
        // this is only reached if the login fails
        this.showFailure = true;
      }
      this.isLoading = false;
    },
    setLoginInfo(field, value) {
      this.loginDetails[field] = value;
      this.credsPresent = (this.loginDetails.Email && this.loginDetails.Password);
    },
    async validateGuid() {
      let resp = await NopService.validateGuid(this.guid);
      this.showSuccess = (resp.data.Status == 1);
      this.message = resp.data.Message;
    },
  },
};
</script>
